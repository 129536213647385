<template>
	<div >
		<el-card class="box-card">
			<div>
				<form-viewer ref="formViewer" v-model="formEditVal" :buildData="formEditCode"></form-viewer>
			</div>
		</el-card>
	</div>
</template>

<script>
import formBuilder from '../../components/common/formDesigner/formBuilder'
import formViewer from '../../components/common/formDesigner/formViewer'
import {codemirror} from 'vue-codemirror';
// 核心样式
import 'codemirror/lib/codemirror.css';
// 引入主题后还需要在 options 中指定主题才会生效
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/javascript/javascript'
const options = {
	tabSize: 2, // 缩进格式
	theme: 'dracula', // 主题，对应主题库 JS 需要提前引入
	lineNumbers: true, // 显示行号
	line: true,
	styleActiveLine: true, // 高亮选中行
	hintOptions: {
		completeSingle: true // 当匹配只有一项的时候是否自动补全
	}
}
export default {
	components:{
		formBuilder,
		codemirror,
		formViewer
	},
	data(){
		return{
			formCode:'',
			formVal:'',
			formEditVal:'',
			formEditCode:'',
			tabName:'view',
			tableTabs: [{
				title: '查看',
				name: 'view',
			}],
			codeMirror:options
		}
	},
	created() {

	},
	mounted(){
		this.getDiyTemplate();
		// this.getRoomContent();
	},

	methods:{
		async getDiyTemplate() {
      const formValue = JSON.parse(localStorage.getItem("formValue"));

      const formEditVal = {};
      for (let i = 0; i < formValue.list.length; i++) {

        if (formValue.list[i].value != "") {
          formEditVal[formValue.list[i].id] = formValue.list[i].value;
        } else {
          if (formValue.list[i].compType === "editor") {//富文本
            const article = await this.getRandomArticle();
            formEditVal[formValue.list[i].id] = article.content;
          } else if (formValue.list[i].compType === "textarea") {//多行
            const article = await this.getRandomArticle();
            formEditVal[formValue.list[i].id] = article.title;
          } else if (formValue.list[i].compType === "input") {//单行
            const article = await this.getRandomArticle();
            formEditVal[formValue.list[i].id] = article.title;
          } else if (formValue.list[i].compType === "date") {//日期
            formEditVal[formValue.list[i].id] = "2023年9月4日";
          } else if (formValue.list[i].compType === "time") {//时间
            formEditVal[formValue.list[i].id] = "2023年9月4日15:50:04";
          } else if (formValue.list[i].compType === "Switch") {
            formEditVal[formValue.list[i].id] = "true";
          } else if (formValue.list[i].compType === "select") {
            const article = await this.getRandomArticle();
            formEditVal[formValue.list[i].id] = article.title;
          } else if (formValue.list[i].compType === "radio") {
            formEditVal[formValue.list[i].id] = 1;
          } else if (formValue.list[i].compType === "checkbox") {
            formEditVal[formValue.list[i].id] = [1];
          } else if (formValue.list[i].compType === "inputNumber") {
            formEditVal[formValue.list[i].id] = 5;
          } else if (formValue.list[i].compType === "slider") {
            formEditVal[formValue.list[i].id] = 5;
          } else if (formValue.list[i].compType === "rate") {
            formEditVal[formValue.list[i].id] = 5;
          } else if (formValue.list[i].compType === "colorPicker") {
            formEditVal[formValue.list[i].id] = "#0055ff";
          } else if (formValue.list[i].compType === "upload") {
            formEditVal[formValue.list[i].id] = [{
              "name": "20230904_155956_882231.png",
              "url": "https://oss.baigongbao.com/Template/2023/09/04/20230904_155956_882231.png"
            }];
          } else if (formValue.list[i].compType === "cascader") {
            formEditVal[formValue.list[i].id] = ["zhinan", "shejiyuanze", "yizhi"];
          } else {
            console.log(formValue.list[i].id, formValue.list[i].compType);
          }
        }


        this.formCode = JSON.stringify(formValue);
        this.formEditCode = JSON.stringify(formValue);
        this.formEditVal = JSON.stringify(formEditVal);

        // this.getRandomArticle();
      }
    },
		// 获取随机内容
		getRandomArticle(){
			return new Promise((resolve,reject)=>{
				const index = Math.floor(Math.random() * 9); //随机0-9
				this.newApi.getTodayHeadlines({}).then((res)=>{
					resolve(res.data[index]) ;
				}).catch((err)=>{
					reject(err)
				})
			})

		},
		editFormData() {
			this.$refs['formBuilder'][0].editDate();
		},

		handlerSubForm(){
			if (this.$route.query.enrollId) {
				this.$refs['formBuilder'][0].toDate();
			} else {
				this.$refs['formBuilder'][0].validate();
			}
			if(this.formVal!==''){
				this.addNewTab();
				this.addEditTab();
			}
		},
		addNewTab(){
			if(this.tableTabs.length<4){
				this.tableTabs.push({
					title: '查看表单',
					name: 'view'
				});
			}

		},
		addEditTab(){
			if(this.tableTabs.length<5){
				this.tableTabs.push({
					title: '表单数据回显',
					name: 'edit'
				});
			}

		}
	},
	computed:{
		itemList(){
			if(this.formCode!==''){
				const form = JSON.parse(this.formCode);
				return form.list;
			}else{
				return [];
			}
		},
		code:{
			get() {
				if(this.tabName ==='form'){
					return ''
				}else if(this.tabName === 'config'){
					return this.formCode
				}else{
					return this.formVal
				}
			},
			set(){}
		}
	}
}
</script>

<style scoped>
/* .box-card >>> .el-tabs__header{
  margin: 0px 0px 0px 0px;
} */

.box-card{
	width:60%;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin:auto;
	overflow: auto;
	display: block;
}
#app{
	position: relative;
}
</style>
